import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/smartphonehand44676902_web 1.png";
import team from "../img/screenmenow-background-screening 1.png";

function EmployeeAccessControl() {
  return (
    <>
      <div className="p-5">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h1>
              Virtual Badge offers workforce identity and management solutions. 
            </h1>
            <h4 style={{ marginTop: "2em" }}>
              We offer customizable, low-cost service packages to businesses,
              non-profits, and government agencies, allowing quick selection of
              required checks and screenings to meet organizational and
              regulatory needs.
            </h4>
          </div>
          <div className="col-6 text-center">
            <img
              src={teamLogo}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "400px" }}
            />
            <div className="mt-4">
              <button
                style={{
                  color: "#203AAB",
                  backgroundColor: "#F7921E",
                  fontWeight: "bold",
                  border: "none",
                  padding: "1em 1.5em",
                }}
              >
                Contact options
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row  p-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="col-6 align-items-center" style={{ color: "#203AAB" }}>
          <h3>What Does ClearStar Offer in Access Control? </h3>
          <div style={{ marginTop: "1em" }}>
            ClearStar is integrated with Virtual Badge, which offers workforce
            identity and management solutions.
          </div>
          <ul className="mt-2">
            <li>
              Single electronic badge combines background check and access
              control
            </li>
            <li>
              Only E-Badge tied to a background check with embedded photo to
              validate identity
            </li>
            <li>Patented badge technology with robust features:</li>
            <ul className="list-style-type: disc;">
              <li>Issue/expire badges remotely</li>
              <li>
                Track time and location on site via GPS (Global Positioning
                System)
              </li>
              <li>Issue badges based on different credentials</li>
              <li>Manage visitors and temporary badges</li>
              <li>Issue tasks and track work assignments</li>
              <li>
                Store documentation, trainings, certifications, licenses, and
                skills
              </li>
            </ul>

            <li>
              QR (Quick Response) code embedded to easily validate badge in the
              field
            </li>
            <li>Full reporting dashboard and accountability tracking</li>
            <li>Badge can be auto-controlled based on results of rescreens</li>
          </ul>
          <div>
            <b> Full Package Options:</b> ClearStar can offer low-cost service
            packages to any business, non-profit, governmental, etc. that can be
            tailored to the needs of that organization; allowing you to quickly
            and easily select the required checks/screenings as required by your
            organization, regulating agencies, etc
          </div>
          <div style={{ marginTop: "2em" }}>
            <b> Tailored/Customized Package Options:</b>  ClearStar can work
            with your hiring, talent acquisition or HR teams to develop
            Pre-Employment checks and screening options to fit your company
            needs.  So you get the data you need.  Simply and Quickly
          </div>
          <div style={{ marginBottom: "2em", marginTop: "2em" }}>
            Our Consultative Approach:  At ClearStar, we can work directly with
            your HR, Legal, Risk, and Compliance teams to determine the needs of
            your organization so you get exactly the information you need to
            hire the best people. 
          </div>
          <div style={{ marginTop: "2em", marginBottom: "2em" }}>
            Technology Driven Approach:  ClearStar technology seamlessly
            integrates with many of the most widely used HR/Payroll and
            Applicant Tracking Systems (ATS) to simplify and streamline the
            background check/screening process.  Reduce paperwork with fully
            digital and mobile-friendly solutions
          </div>
          <div>Technology Driven Solutions</div>
          <ul style={{ marginTop: "2em" }}>
            <li>
              ScreenMeNow: Mobile solution helps you verify candidates quickly
              by allowing them to enter info and e-signature consent from mobile
              devices
            </li>
            <li>
              ClearMD: Streamlines the drug and clinical test ordering process,
              which helps to eliminate the errors and hassles of paper forms. 
            </li>
            <li>
              ClearID: On-the-spot ID validation and facial recognition to make
              hiring contract labor a snap.
            </li>
            <li>Virtual Badge: Remotely managed ID badge for employees </li>
            <li>Applicant Tracking System (ATS) Integrations </li>
            <li>HR/Payroll System Integrations </li>
            <li>
              Assured Compliance®: Real-time compliance tool exclusively for
              ClearStar clients 
            </li>
          </ul>
        </div>
        <div className="col-6  " style={{padding:'1em 7em'}}>
          <div
            style={{
              backgroundColor: "white",
              color: "#203AAB",
              padding: "5em 6em",
              borderRadius:'22px'
            }}
          >
            <h3>Background Check and Screening options</h3>
            <ul style={{marginTop:'2em', marginBottom:'2em'}}>
              <li>
                Criminal record searches at federal, state, and county levels
              </li>
              <li>
                Market-specific searches (e.g. FDIC: Federal Deposit Insurance
                Corporation)
              </li>
              <li>Sex offender registry searches</li>
              <li>Credit history searches</li>
              <li>Motor vehicle record searches</li>
              <li>Civil record searches</li>
              <li>Employment verification background check</li>
              <li>Global searches in 230+ countries</li>
              <li>Education verification background check</li>
              <li>
                Fair Credit Reporting Act (FCRA)-compliant social media searches
              </li>
              <li>Licenses and credentialing</li>
              <li>Personal reference checks</li>
            </ul>
            <h3>Additional Screening Services</h3>
            <ul style={{marginTop:'2em'}}>
              <li>ClearStar Drug and Clinical Testing</li>
              <li>Social Media Screening</li>
              <li>Occupational Health Screening</li>
              <li>Credit, Civil Court, DMV Checks</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeAccessControl;
