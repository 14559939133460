
import './App.css';
import Navbar from './Navbar/Navbar';
// import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        
     
<Navbar/>
      </header>
    </div>
  );
}

export default App;
