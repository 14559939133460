import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AssessmentSteps() {
  const styles = {
    container: {
      textAlign: 'center',
      marginTop: '3rem',
      marginBottom: '3rem',
      color: '#203AAB',
      padding:'0em 5em'
    },
    stepNumber: {
      fontSize: '3rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
      lineHeight: '4rem', // Adjust the height by changing the line height
    },
    heading: {
      marginBottom: '3rem',
      color: '#203AAB',
    },
    text: {
      fontSize: '1rem',
    //   lineHeight: '1.5',
      color: '#203AAB',
      padding:'0px 4em',
      fontWeight:'bold',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Build your own assessments</h1>
      <div className="row">
        <div className="col-md-3">
          <div style={styles.stepNumber}>1</div>
          <p style={styles.text}>Share your job analysis or job description(s) with us.</p>
        </div>
        <div className="col-md-3">
          <div style={styles.stepNumber}>2</div>
          <p style={styles.text}>
            Our assessment experts use their “I/O Intelligence” to identify the critical skills required of the job.
          </p>
        </div>
        <div className="col-md-3">
          <div style={styles.stepNumber}>3</div>
          <p style={styles.text}>
            We then build your skills-based assessment using our platform’s proprietary test building functionality at
            no additional charge.
          </p>
        </div>
        <div className="col-md-3">
          <div style={styles.stepNumber}>4</div>
          <p style={styles.text}>
            The new custom test is added to your account, and you are ready to test your candidate(s), usually within 24
            hours.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSteps;
