import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from  "../img/Teamlogo.png";

function AvoidHiringMistakes() {
  return (
    <>
  

<div className="row align-items-center p-5" style={{backgroundColor:''}}>
<div className="col-6 " style={{color:'#203AAB'}}>
  <h1>Why use custom assessments to hire?</h1>
  <div style={{marginTop:'2em'}}>
  No matter how great an applicant looks on paper or how smooth they appear during the job interview, many are experts at fooling the most experienced recruiter. Difficult personality characteristics, low skill levels and even issues with integrity may not surface until you have made that costly hiring decision. You are then left to deal with the emotional strain and potential legal problems associated with having to terminate a bad hire, not to mention all the hard costs associated with having to recruit again - more time spent on interviews, re-training and loss of productivity. 
 
  </div>
  <div style={{marginTop:'2em'}}>
  The answer to deal with these costly hiring mistakes is to assess candidates for their job fit. Our recommendation is to use our custom approach to pre-employment hiring assessments to ensure that your organization only testing for behaviors and skills that are relevant to the job.
 
  </div>

</div>
<div className="col-6 align-items-center ">
  {/* <img
    src={teamLogo}
    alt="Hiring Challenge"
    className="img-fluid"
    style={{width:'450px'}}
  /> */}
  <div style={{color:'#203AAB', backgroundColor:'white',border:'1px solid white', borderRadius:'22px',margin:'2em 6.5em',justifyContent:'start'}} className="  align-items-start  p-4 ">

 <div style={{backgroundColor:'#F5F5F5', padding:' 2em 2em',borderRadius:'22px'}}>
 <h2 style={{fontWeight:'bold'}}>
  Try it before you buy it
  </h2>
  <div style={{fontSize:'20px',marginTop:'1em'}}>
  We invite you to try any of our pre-employment assessments for any job description.
  </div>
  <div style={{fontSize:'20px' ,marginTop:'1em'}}>
  Connect with a BusinessValue advisor to request a free trial on your next job opening.
  </div>
 </div>

  <div style={{alignItems:'center',marginTop:'1.5em'}} className="ms-5 " >
    <button style={{color:'#203AAB',border:'none',backgroundColor:'#F7921E',fontWeight:'bold',fontSize:'24px',padding:'5px 18px',borderRadius:'10px'}}>
    Connect with an advisor
    </button>
  </div>
  </div>

   

</div>
</div>
</>
  );
}

export default AvoidHiringMistakes;
