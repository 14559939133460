import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo1 from "../img/VetOwnedBusinessLogo 1.png";
import logo2 from  "../img/WomenOwnedBusinessLogo 1.png";
import logo3 from "../img/_x2014_ÎÓÈ_x5F_1.png";

function FooterComponent() {
  return (
    <div style={styles.footerContainer} className="">
      <h2 style={styles.heading}>Connect Today, Transform Forever.</h2>
      <p style={styles.subheading}>Unleashing AI Excellence Together.</p>
    
   <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>

   <div>

<button style={styles.button} className="btn btn-warning">
    Let's start a conversation
</button>

</div>
<div

  style={styles.iconContainer}
  className="d-flex justify-content-end align-items-center mt-4"
>
<img

src={logo1}
alt="Veteran Owned Business"
style={styles.icon}
/>
<img
src={logo2}
alt="Certified Women Owned Business"
style={styles.icon}
/>
<img src={logo3}
alt="AI Icon" style={styles.icon} />
</div>

   </div>

      <footer style={styles.footer}>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div> &copy; Copyright 2011-2024 BusinessValue Inc</div>
          <div style={{justifyContent:'space-Between'}}>
          <ul className="list-inline" >
  <li className="list-inline-item " style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>Home</a>
  </li>
  <li className="list-inline-item mr-5" style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>Partner</a>
  </li>
  <li className="list-inline-item mr-5" style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>About Us</a>
  </li>
  <li className="list-inline-item mr-5" style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>Terms & Conditions</a>
  </li>
  <li className="list-inline-item mr-5" style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>Privacy Policy</a>
  </li>
  <li className="list-inline-item mr-5" style={{marginRight:'0.5em',fontSize:'14px'}}>
    <a href="#" className="text-white" style={{textDecoration:'none'}}>Contact Us</a>
  </li>
</ul>

          </div>
        </div>
      </footer>
    </div>
  );
}

const styles = {
  footerContainer: {
    backgroundColor: "#1e3a8a", // Blue background
    color: "white",
    padding: "40px 20px",
    paddingLeft:'5em',
    paddingRight:'5em'
  },
  heading: {
    fontSize: "38px",
    fontWeight: "bold",
  },
  subheading: {
    fontSize: "28px",
    margin: "10px 0",
  },
  button: {
    marginTop: "20px",
    backgroundColor: "#F7921E", // Orange button
    border: "none",
    color:'#203AAB',
    fontWeight:'bold',
    fontSize: "18px",
    padding: "10px 20px",
  },
  iconContainer: {
    marginTop: "30px",
  },
  icon: {
    width: "5em",
    paddingBottom:'1.5em',
    marginRight:'2em',
    // height: "60px",
    // margin: "0 15px",
  },
  footer: {
    // marginTop: "30px",
  },
  footerLinks: {
    marginTop: "10px",
    fontSize: "14px",
  },
};

export default FooterComponent;
