import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from  "../img/Customer_service_ratings_480329143web-min 1.png";

function BuildCustomerLoyalty() {
  return (
    <>
    <div className="p-5">
      <div className="row align-items-center">
        <div className="col-6 " style={{color:'#203AAB'}}>
          <h1>Selection beyond résumés and interviews</h1>
          <div style={{marginTop:'2em'}}>
          For over 35 years, we've been dedicated to helping companies accurately identify candidates who are not only the right fit for the job but also align with the organization's culture and values. We recognize that finding the right people for the job can be challenging, especially when relying solely on traditional methods like reviewing résumés and conducting interviews. These methods often don't provide a complete picture of a candidate's potential, which can lead to costly hiring mistakes.
          </div>
        </div>
        <div className="col-6 text-center">
          <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{width:'350px'}}
          />
          <div style={{marginTop:'1em'}}> 
          <button style={{padding:'5px 16px',fontWeight:'bold',border:'none' ,color:'#203AAB',backgroundColor:'#F7921E'}}>Let’s start a conversation</button>

          </div>
        </div>
      </div>
     
    </div>

<div className="row  p-5" style={{backgroundColor:'#F5F5F5'}}>
<div className="col-6 align-items-center" style={{color:'#203AAB'}}>
  <div>Our extensive experience has shown that when companies implement our recom mended processes—pre-qualifying candidates, conducting structured interviews, utilizing reliable assessment tools, and maintaining clear communication—they can significantly reduce turnover by anywhere from 15 to 50%. This reduction in turnover translates to substantial Customer loyalty is directly related to how everyone in your communicates and interacts with the people they encounter consistently every day.  Our experience is that by using our customer service profile assessment you will be assured that your customers will never be mistreated, or disappointed in the way anyone in your company interacts with them.</div>
  <div style={{marginTop:'2em'}}>
  The Customer Service Profile™ (CSP) measures how well a person fits specific customer service jobs in an organization. It is used primarily for selecting, onboarding, and managing customer service employees.
 
  </div>
  <div style={{marginTop:'2em'}}>
  The CSP also looks at what your current and future employees believe is a high level of customer service, while at the same time showing where they align or misalign with the company’s perspective. We have a general industry version of this assessment, as well as vertical specialties in hospitality, healthcare, financial services, and retail.
 
  </div>

</div>
<div className="col-6 ">

  <div style={{color:'#203AAB', backgroundColor:'white',border:'1px solid white', borderRadius:'22px',fontWeight:'bold',margin:'1em 6.5em'}} className="   p-4 ">

  <div style={{fontSize:'20px'}}>
  “Start with good people who are right for the job; train and motivate them; give them opportunity to advance; and your organization will succeed.”
  </div>
 
  <div style={{fontSize:'22px' ,marginTop:'1em'}}>
  - J.W. Marriott
  </div>

  </div>



</div>
</div>
<div className="row p-5" style={{color:'#203AAB'}}>

<div className="col-6 align-items-center">

<h3>Why assess customer service people?</h3>
<div style={{marginTop:'1.5em'}}>
Given the opportunity to land a new job or promotion, people may offer lip-service instead of the truth. Additionally, much of their success depends on the specific type of job and the organization in which they would work. Success seldom transfers automatically. The cost of a bad hire that is customer-facing is very high, considering the hiring and ramp-up costs, poor productivity, lost revenue, and potential damage to the organization’s reputation.
</div>
<div style={{marginTop:'1.5em'}}>
 The CSP offers an objective, inside look at the behaviors and motives of customer service job candidates to help you make better hiring, promotion, and organizational decisions. Giving this information to managers helps them be more effective and get the very most from their people.
</div>
<div style={{marginTop:'1.5em'}}>
How does the Customer Service Profile work? Prior to assessing candidates, our experts help you develop peak performance models for your customer service jobs by providing multiple questions against which potential candidates are measured. Once these performance models are established, our clients deliver the CSP to their candidates over the Internet; hiring managers or HR administrators simply forward a link.
</div>
<div style={{marginTop:'1.5em'}}>
The assessment does not need to be monitored, so the candidate can take it from any computer with Internet access. The system instantly scores the assessment and informs hiring managers where they can access the results.
</div>
<div style={{marginTop:'1.5em'}}>
The hiring manager can use the results as a screening tool, or to assist them in the interviewing, selection, or onboarding process.
</div>

</div>



</div>
</>
  );
}

export default BuildCustomerLoyalty;
