 import React from 'react'
import dashlogo from  '../img/daashboardFlow.png';
import AIServiceSection from "./AIServiceSection";
// import IntegratedBusinessProcess from './IntegratedBusinessProcess';
import FooterComponent from './FooterComponent';
import BuildIntegratedBusinessProcess from './BuildIntegratedBusinessProcess';
 
 export default function Dashboard() {
   return (
    <>
     <div style={{backgroundColor:'blue',padding:'0px'}}>
        <AIServiceSection/>
        </div>
     
        <img src={dashlogo} alt='' style={{width:'100%'}}/>    
        {/* <IntegratedBusinessProcess/> */}
        <BuildIntegratedBusinessProcess/>
     <div style={{backgroundColor:'blue',padding:'0px'}}>

        <FooterComponent/>

     </div>
     </>
   )
 }
 