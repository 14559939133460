import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./img/logo.png";
import "./Dashboard/dashboard.js";
import Dashboard from "./Dashboard/dashboard.js";
import HiringSystem from "./HiringSystem/Hiring-System.js";
import BackgroundChecks from "./Background Checks/Background Checks.js";
import SalesPerformance from "./SalesPerformance/salesPerformance.js";
import Assessments from "./Assessments/Assessments.js";


function Navbar() {
  const [activeTab, setActiveTab] = useState("Home");

  const navbarStyle = {
    backgroundColor: "#f0f0f0",
    width: "100%",
    borderBottom: "1px solid #ccc",
  };

  const brandStyle = {
    fontWeight: "bold",
    fontSize: "1.5rem",
  };

  const brandNameStyle = {
    color: "rgba(0, 80, 164, 1)",
  };

  const brandSubtextStyle = {
    color: "#666",
    fontWeight: "normal",
  };

  const navLinkStyle = {
    color: "#203AAB",
    fontSize: "1rem",
    cursor: "pointer",
  };

  const activeLinkStyle = {
    backgroundColor: "#fff",
    borderRadius: "5px",
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Home":
        return (
          <div style={{ width: "100%" }}>
            <Dashboard />
          </div>
        );
      case "Hiring Systems":
        return (
          <div>
            <HiringSystem/>
           

          </div> 
          ) ;
      case "Background Checks":
        return( 
        <div>
          <BackgroundChecks/>
          
          </div>);
      case "Assessments":
        return (
        <div>
          <Assessments/>
          </div>);
      case "Sales Performance":
        return (
        <div>
          <SalesPerformance/>
          {/* Improve your Sales Performance. */}
          </div>);
      case "Web Services":
        return <div>Our Web Services offerings.</div>;
      case "Login":
        return <div>Please log in to your account.</div>;
      default:
        return <div>Welcome to the Home page!</div>;
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg" style={navbarStyle}>
        <div className="container-fluid" style={{display:'flex',justifyContent:"space-around"}}>
          <div className="me-5 ms-4">
            <img src={logo} alt="" />
          </div>

          <div className="collapse navbar-collapse text-end" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item me-4 ms-5">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Home" ? activeLinkStyle : {}),
                  }}
                  onClick={() => setActiveTab("Home")}
                >
                  Home
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Hiring Systems" ? activeLinkStyle : {}),
                  }}
                  onClick={() => setActiveTab("Hiring Systems")}
                >
                  Hiring Systems
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Background Checks"
                      ? activeLinkStyle
                      : {}),
                  }}
                  onClick={() => setActiveTab("Background Checks")}
                >
                  Background Checks
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Assessments" ? activeLinkStyle : {}),
                  }}
                  onClick={() => setActiveTab("Assessments")}
                >
                  Assessments
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Sales Performance"
                      ? activeLinkStyle
                      : {}),
                  }}
                  onClick={() => setActiveTab("Sales Performance")}
                >
                  Sales Performance
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Web Services" ? activeLinkStyle : {}),
                  }}
                  onClick={() => setActiveTab("Web Services")}
                >
                  Web Services
                </a>
              </li>
              <li className="nav-item me-4">
                <a
                  className="nav-link"
                  href="#"
                  style={{
                    ...navLinkStyle,
                    ...(activeTab === "Login" ? activeLinkStyle : {}),
                  }}
                  onClick={() => setActiveTab("Login")}
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid  p-0">{renderContent()}</div>
    </>
  );
}

export default Navbar;
