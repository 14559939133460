import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/Sales_leader_627167567web 1.png";

function SalesPerformance
() {
  return (
    <>
    <div style={{backgroundColor:'#203AAB',color:'white',padding:'10px 2em',fontSize:'24px',fontWeight:'bold'}}>
        <div>Sales Performance</div>
    </div>
      <div
        className="row  p-5"
        style={{ backgroundColor: "" }}
      >
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h1>Take your company to the next level</h1>
          <h3 style={{ marginTop: "2em" }}>
          Business teamwork is crucial for companies to achieve their goals swiftly and efficiently. 
          </h3>
          <div style={{marginTop:'2em'}}>
            <button style={{color:'#203AAB',backgroundColor:'#F7921E',padding:'0.7em 2em',fontWeight:'bold',border:'none',borderRadius:'10px'}}>
              Contact options
              </button>
          </div>
         
        </div>
        <div className="col-6 text-center">

         <div >
         <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{ width: "400px" }}
          />
         </div>
       

        </div>
      </div>

      <div
        className="row  p-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <div>Business teamwork is crucial for companies to achieve their goals swiftly and efficiently. Achieving these goals requires more than just one individual; it necessitates a collaborative effort. In essence, teamwork involves the combined performance of individuals with diverse skills, working together to accomplish a common objective.</div>
          <div style={{marginTop:'1em'}}>
          Therefore, if you own a business or plan to start one, make it a priority to foster teamwork. Encouraging collaboration will benefit the company and create a positive work environment for all employees.
          </div>
          <div style={{marginTop:'1em'}}>
          As team members enhance their knowledge, capabilities, and skills, the business reaps the rewards. Teamwork fosters effective communication, enabling the exchange of ideas among members. This, in turn, ensures an equitable distribution of tasks, with work being delegated appropriately to each team member.
          </div>
        <ul style={{marginTop:'1em'}}>
          
          <li>Working as a team encourages personal accountability.</li>
          <li>Achieving individual goals contributes to quicker company goal attainment.</li>
          <li>Efficient completion of personal responsibilities benefits the company as a whole.</li>
          <li>Teamwork encourages better conflict resolution through the creation of bonds.</li>
          <li>Stronger bonds make it easier to resolve conflicts quickly and easily.</li>
          
          </ul> 

          <div style={{marginTop:'1em'}}>
          Partnering with BusinessValue365 benefits the company and fosters a positive work environment for all employees.
          </div>
        
         
        </div>
        <div className="col-6 " >

         <div style={{color:'#203AAB',margin:'2em 10em',padding:'2em 2em',background:'white',borderRadius:'22px'}}>
         <h4>
         Effective leadership recognizes the value of teamwork
         </h4>
         <div style={{marginTop:'2em'}}>
         <h6>Achieving goals requires more than just one individual; it necessitates a collaborative effort. In essence, teamwork involves the combined performance of individuals with diverse skills, working together to accomplish a common objective.</h6>
         </div>
        
         </div>
       

        </div>
      </div>
    </>
  );
}

export default SalesPerformance
;
