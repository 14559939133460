import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/screenmenow_87896956web-min 2.png";
import team from "../img/screenmenow-background-screening 1.png";


function ScreenMeNow() {
  return (
    <>
      <div className="p-5">
        <div className="row align-items-center">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h1>
              Landing top talent is easier with a simple background screening
              process. 
            </h1>
            <h4 style={{ marginTop: "2em" }}>
              Landing top talent is easier with a simple background screening
              process. ScreenMeNow helps you verify candidates quickly by
              allowing them to enter info and e-signature consent from mobile
              devices.
            </h4>
          </div>
          <div className="col-6 text-center">
            <img
              src={teamLogo}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "400px" }}
            />
            <div className="mt-4">
              <button
                style={{
                  color: "#203AAB",
                  backgroundColor: "#F7921E",
                  fontWeight: "bold",
                  border: "none",
                  padding: "1em 1.5em",
                }}
              >
                Contact options
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row align-items-center p-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h3>How it Works:</h3>
          <ol>
            <li>Set up custom screening packages</li>
            <li>
              Send an email invite to the candidate to complete the application
            </li>
            <li>
              Candidate enters basic information and provides e-signature
              consent
            </li>
            <li>
              Add ClearID or ClearMD to validate ID or perform a drug or
              clinical test
            </li>
            <li>Screening status updates are sent via text or email</li>
          </ol>
          <h3 style={{marginTop:'2em'}}>
            How it Works for YOU:
            </h3>
            <ul>
              <li>Accelerates data entry and minimizes mistakes</li>
              <li>
                Upload custom forms and store e-signatures to maintain
                compliance
              </li>
<li>Completely customizable – custom branding available</li>
<li>Gives candidates ownership of screening process</li>
<li>Meet candidates where they are – on their smartphones or tablets</li>
            </ul>
          
        </div>
        <div className="col-6 text-center">
          <img
    src={team}
    alt="Hiring Challenge"
    className="img-fluid"
    style={{width:'370px'}}
  />
          {/* <div
            style={{
              color: "#203AAB",
              backgroundColor: "white",
              border: "1px solid white",
              borderRadius: "22px",
              fontWeight: "bold",
              margin: "2em 6.5em",
            }}
            className=" align-items-center  p-4 "
          >
            <div style={{ fontSize: "20px" }}>
              By following our recommended processes,
              <p>companies can cut turnover by </p>
            </div>
            <div style={{ fontSize: "65px", fontWeight: "bold" }}>
              15 to 50%
            </div>
            <div style={{ fontSize: "22px", marginTop: "1em" }}>
              boosting productivity and satisfaction.
            </div>
          </div> */}

          <div>
            {/* <button
              style={{
                color: "#203AAB",
                border: "none",
                backgroundColor: "#F7921E",
                fontWeight: "bold",
                fontSize: "24px",
                padding: "5px 18px",
                borderRadius: "10px",
              }}
            >
              Let’s start a conversation
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ScreenMeNow;
