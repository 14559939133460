import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/Assessments_215509157web 1.png";
import SkillsGrid from "./SkillsGrid";
import AssessmentSteps from "./AssessmentSteps";
import logosTall from "../img/logosTall 1.png";
function Assessments() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#203AAB",
          color: "white",
          padding: "10px 2em",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        <div>Assessments</div>
      </div>
      <div className="row  p-5" style={{ backgroundColor: "" }}>
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h1>Innovative assessment solutions</h1>
          <h3 style={{ marginTop: "2em" }}>
            Hire better employees, increase productivity and reduce turnover.{" "}
          </h3>
          <div style={{ marginTop: "2em" }}>
            <button
              style={{
                color: "#203AAB",
                backgroundColor: "#F7921E",
                padding: "0.7em 2em",
                fontWeight: "bold",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Contact options
            </button>
          </div>
        </div>
        <div className="col-6 text-center">
          <div>
            <img
              src={teamLogo}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "400px" }}
            />
          </div>
        </div>
      </div>

      <div className="row  p-5" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <div style={{ fontSize: "24px", fontWeight: "bold" }}>
            The flexibility of our highly customizable assessments provides each
            business the opportunity to focus only on those characteristics that
            are critical for each job.
          </div>
          <div>
            <div>
              <SkillsGrid />
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div
            style={{
              color: "#203AAB",
              margin: "2em 10em",
              padding: "2em 2em",
              background: "white",
              borderRadius: "22px",
            }}
          >
            <h3>Benefits to Your Organization:</h3>
            <div style={{ marginTop: "2em" }}>
              <h6>Improve team performance and workplace harmony </h6>
              <h6 style={{ marginTop: "1.5em" }}>
                Reduce employee turnover and disengagement{" "}
              </h6>
              <h6 style={{ marginTop: "1.5em" }}>
                Select people most likely to succeed for each role and team
              </h6>
              <h6 style={{ marginTop: "1.5em" }}>
                Improve communication and relationships between employees{" "}
              </h6>
              <h6 style={{ marginTop: "1.5em" }}>
                Build a consistent and strong culture of openness, growth, and
                care
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <AssessmentSteps />
      </div>
      <div>
        <div className="row  p-5" style={{ backgroundColor: "#F5F5F5" }}>
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h4>
              Assessments365 assessments capitalize on and incorporate “best l
              needs of the human resource professional.
            </h4>
            <h5 style={{ marginTop: "2em" }}>
              Leading Edge Technology Platform
            </h5>
            <div style={{ marginTop: "1em", marginBottom: "2em" }}>
              The Assessments365 assessment customization and delivery platform
              utilizes a unique proprietary algorithm, we tell the platform the
              job-related competencies we want to assess and the platform
              develops an assessment that measures only those competencies. The
              Platform also allows for “Star Profile” benchmarking. Candidates
              falling within your ideal score ranges will be identified with a
              star in the platform and you will be automatically notified when a
              “Star” candidate completes your assessment.
            </div>
            <h5>Validity</h5>
            <div style={{ marginTop: "1em", marginBottom: "2em" }}>
              An assessment’s level of effectiveness is directly related to its
              validity and its reliability. The research conducted to establish
              the validity of the Assessments365 assessments complies with The
              Uniform Guidelines on Employee Selection Procedures (Federal
              Testing Guideline) and the EEOC (Equal Employment Opportunity
              Commission). The results consistently demonstrate that the
              Assessments365 assessments are valid predictors of critical
              aspects of job performance.
            </div>
            <h5>Reliability</h5>
            <div style={{ marginTop: "1em" }}>
              In addition to rigorous validation studies, reliability analyses
              have been performed for each Assessments365 assessment. The
              results of these analyses demonstrate that the Assessments365
              assessment items are consistent in measuring the job-related
              competencies they are intended to measure.
            </div>
            <div style={{marginTop:'1em'}}>
            The Assessments365 assessments were specifically developed to help today’s organizations make the right hiring decisions. Extensive research utilizing various statistical methods all conclude that the Assessments365 assessments are valid and reliable tools for predicting a wide range of job-related aptitudes and behaviors.

            </div>
          </div>
          <div className="col-6 ">
            <div style={{color:'#203AAB',backgroundColor:'white',margin:'1em 8em',padding:'2em 4em',borderRadius:'22px'}}>
             <h4 style={{marginBottom:'2em'}}>Our assessments have helped clients select ideal candidates, resulting in increased productivity and reduced turnover.</h4>
             <h6 >Detailed score reports are delivered within minutes of assessment completion to designated recipients.</h6>
             <h6 style={{marginTop:'2em'}}> Score reports provide detailed analytics, interpretive text that includes expected behaviors, an interview guide and strategies for managing the candidate if hired.</h6>
            </div>
            <div style={{textAlign:'center',marginTop:'2em'}}>
                <button style={{color:'#203AAB',backgroundColor:'#F7921E',padding:'1em 1.5em',border:'none',borderRadius:'5px',fontWeight:'bold'}}>
                Contact options
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row " style={{padding:'1.5em 6em'}}>
    <img src={logosTall} alt=""/>
      </div>
    </>
  );
}

export default Assessments;
