import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/GI_serious_computer_man_1134245630web-min 1.png";
import team from "../img/screenmenow-background-screening 1.png";


function PostHire() {
  return (
    <>
      <div className="p-5">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h1>
            Post employment searches help identify safe applicants. 
            </h1>
            <h4 style={{ marginTop: "2em" }}>
            Employees may pose a safety threat to employers as well as the safety of other employees. Reports include date of accident, case number, type of injury, how injury was incurred, and how long employee was absent from work. Some states require additional fees that may apply.*
            </h4>
          </div>
          <div className="col-6 text-center">
            <img
              src={teamLogo}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "400px" }}
            />
            <div className="mt-4">
              <button
                style={{
                  color: "#203AAB",
                  backgroundColor: "#F7921E",
                  fontWeight: "bold",
                  border: "none",
                  padding: "1em 1.5em",
                }}
              >
                Contact options
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row align-items-center p-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h3>I-9 Data/E-Verify</h3>
        <div style={{marginTop:'1em'}}>
        I-9 Data collects the necessary information for compliance with the I-9 form; this data is used to check work eligibility status through E-Verify. E-Verify is an Internet based system operated by the Department of Homeland Security (DHS) in partnership with the Social Security Administration (SSA) that allows participating employers to electronically verify the employment eligibility of their newly hired employees. We can act as your agent to conduct verifications through the E-Verify system. I-9/E-Verify can only be conducted POST hire. *Average turnaround time: 1-3 business days.
        </div>
        
            <div style={{marginTop:'2em'}}>
            *Average turnaround time: 1-3 business days. Not available in all states. State fees apply. Signed release required
            </div>
          
        </div>
        <div className="col-6 text-center">
          {/* <img
    src={team}
    alt="Hiring Challenge"
    className="img-fluid"
    style={{width:'370px'}}
  /> */}
          {/* <div
            style={{
              color: "#203AAB",
              backgroundColor: "white",
              border: "1px solid white",
              borderRadius: "22px",
              fontWeight: "bold",
              margin: "2em 6.5em",
            }}
            className=" align-items-center  p-4 "
          >
            <div style={{ fontSize: "20px" }}>
              By following our recommended processes,
              <p>companies can cut turnover by </p>
            </div>
            <div style={{ fontSize: "65px", fontWeight: "bold" }}>
              15 to 50%
            </div>
            <div style={{ fontSize: "22px", marginTop: "1em" }}>
              boosting productivity and satisfaction.
            </div>
          </div> */}

          <div>
            {/* <button
              style={{
                color: "#203AAB",
                border: "none",
                backgroundColor: "#F7921E",
                fontWeight: "bold",
                fontSize: "24px",
                padding: "5px 18px",
                borderRadius: "10px",
              }}
            >
              Let’s start a conversation
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PostHire;
