import React from 'react';
import cognitive from "../img/cognitive.png";
import Personality from "../img/Personality.png";
import SituationalJudgment from "../img/SituationalJudgment.png";
import Interests from "../img/Interests.png";
import officeskills from "../img/officeskills.png";
import IndustrySpecific from "../img/IndustrySpecific.png";

const SkillsGrid = () => {
  const skills = [
    {
      imgSrc: cognitive,
      title: 'Cognitive',
      description: 'Measure learning ability and problem solving aptitude.',
    },
    {
      imgSrc: Personality,
      title: 'Personality',
      description: 'Assess characteristics associated with sales, customer service, work ethic, character and more.',
    },
    {
      imgSrc: SituationalJudgment,
      title: 'Situational Judgment',
      description: 'Predict how employees will behave when confronted with specific work situations.',
    },
    {
      imgSrc: Interests,
      title: 'Interests',
      description: "Increase job satisfaction by matching job roles to the individual's interests.",
    },
    {
      imgSrc: officeskills,
      title: 'Office Skills',
      description: 'Determine computer related knowledge levels before you hire.',
    },
    {
      imgSrc: IndustrySpecific,
      title: 'Industry Specific',
      description: 'Measures knowledge of general mechanical concepts.',
    },
  ];

  const skillItemStyle = {
    width: '200px',
    textAlign: 'center',
    marginBottom: '40px',
    padding: '0 20px',
  };

  const skillIconStyle = {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 20px auto',
  };

  const skillTitleStyle = {
    fontSize: '16px',
    color: '#004EA4',
    marginBottom: '10px',
  };

  const skillDescriptionStyle = {
    fontSize: '14px',
    color: '#004EA4',
  };

  return (
    <div className="container-fluid p-0 mt-4">
      <div className="d-flex flex-wrap justify-content-start">
        {skills.map((skill, index) => (
          <div style={skillItemStyle} key={index} className="d-flex flex-column align-items-center">
            <div style={skillIconStyle}>
              <img src={skill.imgSrc} alt={skill.title} style={{ width: '100%', height: 'auto' }} />
            </div>
            <h3 style={skillTitleStyle}>{skill.title}</h3>
            <p style={skillDescriptionStyle}>{skill.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsGrid;
