import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from  "../img/police_860646web-min 1.png";

function CriminalBackgroundScreening
() {
  return (
    <>
    <div className="p-5">
      <div className="row ">
        <div className="col-6 " style={{color:'#203AAB'}}>
          <h1>Protect you assets. With one search, you can explore more than 300 million criminal records.    </h1>
          <h5 style={{fontWeight:'bold',marginTop:'2em'}}>
          Counties, Department of Corrections (DOC), Administration of the Court (AOC) and state sex offender registries covering 49 states and Washington, DC, Guam, and Puerto Rico. 
          </h5>
        </div>
        <div className="col-6 text-center ps-5 ">
         <div style={{}}>
         <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{width:''}}
          />
         </div>
         <div style={{marginTop:'1.2em'}}>
          <button style={{color:'#203AAB',backgroundColor:'#F7921E',padding:'10px 20px',fontSize:'20px',fontWeight:'bold',border:'none',borderRadius:'8px'}}>
          Contact options
          </button>
         </div>
        </div>
      </div>
     
    </div>

<div className="row align-items-center p-5" style={{backgroundColor:'#F5F5F5'}}>
<div className="col-6 " style={{color:'#203AAB'}}>
  <div>Also included are national and international terrorism sources, more than 3.1 million photos and our proprietary database of previously completed reports. Full source description available upon request.  </div>
  <div style={{marginTop:'1em'}}>
  National with Alias is a multi-faceted search that combines National search with the known aliases provided from a SSN trace. With a name, date of birth and SSN, we report the state and the approximate date of issue of the SSN. We then run the SSN through the Death Master Index. Next, we run a SSN trace to obtain a list of alias names, including maiden names. We then use this information to make a final pass through our National criminal database.
 
  </div>
  <div style={{marginTop:'1em'}}>
  The SSN Trace provides the state and year of Social Security Number issuance, even when the number does not match the applicant information provided, and an expanded address history. When “no match” results are returned on the SSN a message that relays “no matching results were found” along with some common reasons for this error will be included on the report and the service will be flagged.  
  </div>

  <h3 style={{marginTop:'2em'}}>Add Monthly National Monitoring Program to the Above Service</h3>
  <div style={{marginTop:'1em'}}>Monitor your employees monthly for reported criminal violations and receive a notification by email if employees commit a felony or misdemeanor. Contact Us to Get Started  401.463.0200</div>
  <h3 style={{marginTop:'1em'}}>County Court Criminal Record Searches</h3>
  <div style={{marginTop:'1em'}}>A County Court Criminal Record Search is a search of felony and misdemeanor county court criminal records located at the county seat or central county courthouse, and are available from county courts located in over 3100 counties nationwide. The search depth is a 7-year search wherever records are available 7 years back or more.  All legally reported felony and misdemeanor conviction records, pending cases and non-conviction records found are reported. Court access fees, if applicable, are not included in the price.  *Average turnaround time is same day to 3 business days.  </div>

<h3 style={{marginTop:'1em'}}>State with Alias (Statewide Criminal with Alias)</h3>
<div style={{marginTop:'1em'}}>
This search contains criminal record information from various reporting agencies throughout a single state. All 50 states (plus Washington, D.C.) are available. Examples of sources that may contribute data are Department of Corrections (DOC), Administration of Courts (AOC), county courts and sex offender registries within a state .  State with Alias combines a State service with the known aliases provided from a Social Security trace. All 50 states (plus Washington, D.C.) are available. *Average turnaround time: Instant  
</div>
<h3 style={{marginTop:'1em'}}>Multi-State Criminal Option</h3>
<div  style={{marginTop:'1em'}}>If you only want to search part of the United States, we offer regionalized packages of the National service. You get the same quality data from instant statewide criminal and sex offender registries, states and sex offender registries, but can custom design your search to include up to 15 states. All 50 states (plus Washington, D.C.) are available.  *Average turnaround time: Instant   State Multi with Alias (MultiState Criminal with Alias) State Multi Alias combines a State Multi search with the known aliases provided from a Social Security trace. *Average turnaround time: Instant   SOR (Sex Offender Registry) With Sex Offender Registry search you will get information from 49 states (plus Washington, D.C.), Guam, and Puerto Rico all with photos. *Average turnaround time: Instant   Watch List (Global Criminal) This service provides an instant database search of 27 national and international lists such as the Office of the Inspector General Health and Human Services Exclusions List, Office of Foreign Assets Control Specially Designated Nationals List, Interpol Fugitives List and FBI Most Wanted List. These lists include individuals involved in terrorist activities, money laundering, illegal imports, fraud against government agencies, violations of federal banking regulations and fugitives from justice. </div>
<div  style={{marginTop:'1em'}}>*Average turnaround time: Instant  </div>
</div>

</div>
</>
  );
}

export default CriminalBackgroundScreening
;
