import React from 'react';
import machineLearningLogo from "../img/machineLearningLogo.png";
import ChatBotLogo from "../img/ChatBotLogo.png";
import DataAnalyticsLogo from "../img/DataAnalyticsLogo.png";
import CustomAISoftwareDevelopmentLOgo from  "../img/Custom AI Software DevelopmentLOgo.png";

function AIServiceSection() {
  const styles = {
    container: {
      backgroundColor: '#203AAB',
      color: 'white',
      textAlign: 'center',
      padding: '50px 0',
    },
    header: {
      fontSize: '36px',
      marginBottom: '10px',
    },
    paragraph: {
      fontSize: '18px',
      marginBottom: '40px',
    },
    services: {
      display: 'flex',
      justifyContent: 'center',
      gap: '30px',
      flexWrap: 'wrap',
    },
    service: {
      textAlign: 'center',
      maxWidth: '200px',
    },
    serviceImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '50%',
      marginBottom: '20px',
    },
    serviceTitle: {
      fontSize: '18px',
    },
    consultationButtonContainer: {
      fontweight:'bold',
      marginTop: '40px',
    },
    consultationButton: {
      backgroundColor: '#F7921E',
      color: '#203AAB',
      fontweight:'bold',
      fontSize: '16px',
      padding: '10px 20px',
      borderRadius: '5px',
      textDecoration: 'none',
      display: 'inline-block',
    },
    consultationButtonHover: {
      backgroundColor: '#ffca2c',
      color: '#203AAB',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Empowering Tomorrow, Today.</h1>
      <p style={styles.paragraph}>AI-Powered Solutions for a Smarter Future.</p>
      <div style={styles.services}>
        <div style={styles.service}>
          <img
            src={machineLearningLogo}
            alt="Machine Learning Solutions"
            style={styles.serviceImage}
          />
          <h5 style={styles.serviceTitle}>Machine Learning Solutions</h5>
        </div>
        <div style={styles.service}>
          <img
            src={ChatBotLogo}
            alt="Chat Bot Development"
            style={styles.serviceImage}
          />
          <h5 style={styles.serviceTitle}>Chat Bot Development</h5>
        </div>
        <div style={styles.service}>
          <img
            src={DataAnalyticsLogo}
            alt="Data Analytics and Insights"
            style={styles.serviceImage}
          />
          <h5 style={styles.serviceTitle}>Data Analytics and Insights</h5>
        </div>
        <div style={styles.service}>
          <img
            src={CustomAISoftwareDevelopmentLOgo}
            alt="Custom AI Software Development"
            style={styles.serviceImage}
          />
          <h5 style={styles.serviceTitle}>Custom AI Software Development</h5>
        </div>
      </div>
      <div style={styles.consultationButtonContainer}>
        <a
          // href="#"
          style={styles.consultationButton}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = styles.consultationButtonHover.backgroundColor;
            e.target.style.color = styles.consultationButtonHover.color;
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = styles.consultationButton.backgroundColor;
            e.target.style.color = styles.consultationButton.color;
          }}
        >
          Request a Free Consultation
        </a>
      </div>
    </div>
  );
}

export default AIServiceSection;
