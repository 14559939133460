import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import CriminalBackgroundScreening from "./case1";
import DrugScreeningServices from "./case2";
import VerificationServices from "./case3";
import ScreenMeNow from "./case4";
import PostHire from "./case5";
import EmployeeAccessControl from "./case6";
// import HiringSection from './case1';
// import AvoidHiringMistakes from './case2';
// import ImproveSalesPerformance from './case3';
// import BuildCustomerLoyalty from './case4';
// import ImproveLeadershipSkills from './case5';
// import BuildYourHumanSystem from './case6';

const BackgroundChecks = () => {
  const [selectedItem, setSelectedItem] = useState("Hire great people");

  const styles = {
    container: {
      backgroundColor: "#E0E7FA",
      //   padding: '20px',
      textAlign: "center",
    },
    header: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "#2845A7",
      padding: "10px 2em",
      textAlign: "left",
      marginBottom: "20px",
    },
    content: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "0 20px",
      flexWrap: "wrap",
    },
    circleItem: {
      width: "180px",
      height: "180px",
      borderRadius: "50%",
      backgroundColor: "white",
      color: "#2845A7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      fontWeight: "bold",
      padding: "10px",
      margin: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    active: {
      backgroundColor: "#2845A7",
      color: "white",
    },
    output: {
      marginTop: "20px",
      padding: "20px",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Hire great people":
        return (
          <CriminalBackgroundScreening/>
          // "You are focusing on hiring great people!"
        );
      case "Avoid hiring mistakes":
        return (
          <DrugScreeningServices/>
          // "You are focusing on hiring great people!"
        );
      case "Improve sales performance":
        return (
          <VerificationServices/>
          // "You are focusing on hiring great people!"
        );
      case "Build customer loyalty":
        return (
          <ScreenMeNow/>
          // "You are focusing on hiring great people!"
        );
      case "Improve leadership skills":
        return (
          <PostHire/>
          // "You are focusing on hiring great people!"
        );
      case "Build your human system":
        return (
          <EmployeeAccessControl/>
          // "You are focusing on hiring great people!"
        );
      default:
        return "Select an option to see more details.";
    }
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>Background Checks</div>
        <div style={styles.content}>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Hire great people" ? styles.active : {}),
            }}
            onClick={() => setSelectedItem("Hire great people")}
          >
            Criminal Background Screening
          </div>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Avoid hiring mistakes"
                ? styles.active
                : {}),
            }}
            onClick={() => setSelectedItem("Avoid hiring mistakes")}
          >
            Drug Screening Services
          </div>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Improve sales performance"
                ? styles.active
                : {}),
            }}
            onClick={() => setSelectedItem("Improve sales performance")}
          >
            Verification Services
          </div>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Build customer loyalty"
                ? styles.active
                : {}),
            }}
            onClick={() => setSelectedItem("Build customer loyalty")}
          >
            Screen Me Now{" "}
          </div>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Improve leadership skills"
                ? styles.active
                : {}),
            }}
            onClick={() => setSelectedItem("Improve leadership skills")}
          >
            Post-Hire
          </div>
          <div
            style={{
              ...styles.circleItem,
              ...(selectedItem === "Build your human system"
                ? styles.active
                : {}),
            }}
            onClick={() => setSelectedItem("Build your human system")}
          >
            Employee Access Control
          </div>
        </div>
      </div>
      <div style={styles.output}>{renderContent()}</div>
    </>
  );
};

export default BackgroundChecks;
