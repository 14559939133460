import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/saleswoman 1.png";
import team from  "../img/ImproveSalesPerformanceLogo.png";

function ImproveSalesPerformance() {
  return (
    <>
      <div className="p-5">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h1>
              Improve sales performance
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "350",
                  marginTop: "2em",
                }}
              >
                To successfully grow your company and retain customers, a
                coordinated and effective sales team is essential. Our advisors,
                who have worked with thousands of companies, understand the
                buying process and have crafted a program to help you improve
                customer acquisition and deal closure. This program is designed
                to maximize the talents of your sales team, as outlined below.
              </div>
               
            </h1>

            <div style={{ marginTop: "0em" }}></div>
          </div>
          <div className="col-6 text-center">
            <img
              src={team}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "450px" }}
            />
          </div>
        </div>
      </div>

      <div className="row  p-5" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h3>
            The Number One Challenge for Companies Today is Top Line Growth
          </h3>
          <div style={{ marginTop: "1em" }}>
            In a recent survey, the number one challenge for business owners and
            sales managers is to improve top line growth and find great sales
            talent.
          </div>
          <div style={{ marginTop: "1em" }}>
            The Credibility Selling System is a way to develop strategies that
            will produce results and recruit, hire and retain great sales
            talent!
          </div>
          <div style={{ marginTop: "2em" }}>
            <h3>About the Credibility Selling System</h3>
            <div>
              Credibility Selling is all about helping salespeople understand
              how to consistently earn new business and acquire new customers by
              establishing credibility with each stakeholder involved in their
              sales opportunities.
            </div>
            <div style={{ marginTop: "1em" }}>
              People don't like to be sold but they love to buy! Why do they
              buy? Fundamentally, one of the following motivations:
            </div>
            <div style={{ marginTop: "1em" }}>
              <ul>
                <li> To solve a problem that keeps them up at night. </li>
                <li>
                  To acquire something new they desire or think they need.
                </li>
                <li>To improve their lot in life.</li>
                <li>To become more successful in achieving their goals.</li>
                <li>To make someone happy.</li>
                <li>
                  To  make sure they take care of the people they care about.
                </li>
                <li>To  better manage their future financial security</li>
              </ul>
            </div>
            <div style={{ marginTop: "1em" }}>
              Credibility Selling is based on the philosophy that people buy
              when they are convinced, they are making a good decision based on
              what they feel is valuable to them individually and their
              stakeholders.
            </div>

            <div style={{ marginTop: "2em" }}>
              <h3>Why Do You Need Credibility Selling System?</h3>
              <div style={{ marginTop: "1em" }}>
                <ul>
                  <li>
                    {" "}
                    Assure your top line growth is predictable and profitable.{" "}
                  </li>
                  <li>Acquire new customers that are profitable to service.</li>
                  <li>
                    Retain and upsell existing customers with additional
                    services.
                  </li>
                  <li>Be able to track your sales opportunities.</li>
                  <li>Be able to develop strategies that create engagement.</li>
                  <li>
                    Be able to coach your sales and customer teams to accomplish
                    your business objectives.
                  </li>
                  <li>
                    Align your sales and customer teams to deliver on your
                    promises.
                  </li>
                  <li>
                    Incorporate your customer-relationship management technology
                    with a sales perspective.
                  </li>
                  <li>
                    Focus on the top sales events to make your weekly sales
                    goals.
                  </li>
                </ul>
              </div>
            </div>

            <div style={{ marginTop: "2em" }}>
              <h3>
                To Achieve Credibility with Customers, You Need to Hire Credible
                Salespeople!
              </h3>
              <div style={{ marginTop: "1em" }}>
                <div>
                  The Credibility Selling System is focused on what salespeople
                  need to do to earn new business:
                  <div style={{ marginTop: "1em" }}>
                    <ul>
                      <li>
                        Need to learn enough credibility to sell your value
                        proposition (not rely on price only)
                      </li>
                      <li>
                        Earn credibility with all the stakeholders and
                        decision-makers involved in the sales opportunity.
                      </li>
                      <li>
                        Have the ability to manage and keep track of where they
                        are in their sales cycle vs. their top competitor.
                      </li>
                      <li>
                        Are engaged in their job and love where they work.
                      </li>
                      <li>
                        {" "}
                        Are driven to earn more commissions month after month.
                      </li>
                    </ul>
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    It's a fact that now more than ever before, it takes great
                    sales talent to consistently bust sale goals and
                    consistently acquire new business. To get this job done, it
                    is critical to attract top sales talent and train them to
                    earn the confidence and trust of the people who purchase
                    your products and services.
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    In order to assure your sales team will consistently earn
                    new business and sell your value proposition at a profit,
                    you must know how to hire salespeople that have what it
                    takes to make this happen, and you need to hire people that
                    have the mental capacity, behavorial traits and motivational
                    interests to become an above-average sales producer.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 " style={{ padding: "0px 6em " }}>
          <div
            style={{
              backgroundColor: "white",
              color: "#203AAB",
              padding: "2em 5em ",
              borderRadius: "22px",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              Here are the six credibility factors salespeople need to achieve
              to win the sale over their competitors; it is based on the
              philosophy that people buy when the company and salesperson have a
              value proposition to offer:
            </div>
            <div style={{ marginTop: "1em" }}>
              <ol type="1">
                <li>
                  {" "}
                  <b>Knowledge Credibility - </b>they need to believe the
                  company or salesperson has expertise in whatever they are
                  talking about
                </li>
                <li>
                  <b> Source Credibility -</b> They need to know if the company
                  or subject-matter expert is a good choice to do business with
                  and stands behind their promises
                </li>
                <li>
                  <b> Solution Credibility - </b>They need to have the
                  confidence that whatever they purchase will satisfy their
                  needs
                </li>
                <li>
                  <b>Task Credibility -</b> They need to know that they will
                  have the product or service to meet their personal timetable.
                </li>
                <li>
                  {" "}
                  <b>Trust Credibility -</b> They need to feel that the company
                  and salesperson they are dealing with are trustworthy
                </li>
                <li>
                  <b>Price Credibility -</b> They need to feel they got their
                  monies worth and paid a reasonably, competitive price. 
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-5">
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <div>
            <h3>Recruit and Hire Great People with Confidence</h3>

            <div style={{ marginTop: "1em" }}>
              Develop a recruiting and selection strategy to ensure you can find
              and hire people who can become great sales producers.
            </div>
            <div style={{ marginTop: "1.5em" }}>
              Determine before you hire if your candidate has these critical,
              credibility selling, success factors:
            </div>

            <ul style={{marginTop:'1em'}}>
              <li>
                Able to learn the knowledge they need to be credible with all
                decision stake holders?
              </li>
              <li>
                Be able to communicate your value proposition to all the buying
                influences?
              </li>
              <li>
                Is enterprising and has the ability to persuade stakeholders
                when presenting ideas?
              </li>
              <li>
                Is creative in developing ways to position ideas that help sell
                your product or service?
              </li>
              <li>
                Are interested and enjoy activities that help people and promote
                the welfare of others?
              </li>
              <li>
                Are prone to pursuing new prospects, is self-sufficient and
                flexible when approaching new prospects?
              </li>
              <li>
                Can they be competitive while maintaining the understanding of
                the prospect's perspective, will they invest the time to listen
                and understand what will guarantee a successful outcome for the
                prospect and your company?
              </li>
              <li>
                Does the candidate have call-reluctance, have a high-energy
                level and able to act quickly to pursue a qualified sales
                opportunity?
              </li>
              <li>
                Is the candidate a self-starter that possesses the ability to
                work in a fast-paced profession?
              </li>
              <li>
                Does the candidate possess the ability to work with a team, lead
                others and at the same time, be diplomatic?{" "}
              </li>
              <li>
                When competition arises, are they ready for the challenge,
                willing to work with you and your management team to overcome
                and earn enough credibility to close the business?
              </li>
              <li>
                Do they possess the attitude to be positive regarding people and
                outcomes?
              </li>
              <li>
                Can they think clearly, be objective in the decision-making and
                not too quick to make hasty decisions?
              </li>
            </ul>
            <div>
                <h3>The Credibility Selling System Closes More Deals</h3>
                <div style={{marginTop:'1.5em'}}>
                In every sales opportunity, there are several stakeholders involved in the decision to buy. Credibility Selling is a system that helps you understand where you are at any given time in the sales process vs. your main competitor and helps you strategize with your sales team to move you closer to the sale.
                </div>
                <div style={{marginTop:'1em'}}>
                The system covers six important credibilities to a close a deal, they are: Knowledge Credibility, Source Credibility, Solution Credibility, Task Credibility, Trust Credibility, and finally Price Credibility.
                </div>
                <div style={{marginTop:'1em'}}>
                We encourage you to contact us to have a brief discussion and sample a few of the tools that are used in this system.
                </div>
            </div>  
          </div>

          <div style={{ marginTop: "0em" }}></div>
        </div>
        <div className="col-6 text-center">
          <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{ width: "450px" }}
          />
          <div className="mt-4">
            <button style={{color:'#203AAB',backgroundColor:'#F7921E',padding:'1.2em 2.5em',border:'none',borderRadius:'5px',fontWeight:'bold'}}>
            Connect with an advisor
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImproveSalesPerformance;
