import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/casual_meeting_747660600web-min 1.png";
import team from "../img/Leadership_512734833web-min 1.png";

function ImproveLeadershipSkills() {
  return (
    <>
      <div className="p-5">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <h1>
              Excellent leadership assures greater profitability
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "350",
                  marginTop: "2em",
                }}
              >
                Great employees may leave some companies because of their weak
                leadership. When we find great leaders, those companies
                generally attain higher engagement levels among their employees.
                A highly engaged workforce means that people are more
                productive, and this results in higher profitability. Isn’t that
                what you want? Ultimately, great companies are a result of great
                leadership. 
              </div>
               
            </h1>

            <div style={{ marginTop: "0em" }}></div>
          </div>
          <div className="col-6 text-center">
            <img
              src={team}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{ width: "350px" }}
            />
            <div style={{ marginTop: "1em" }}>
              <button
                style={{
                  color: "#203AAB",
                  backgroundColor: "#F7921E",
                  fontWeight: "bold",
                  padding: "5px 16px",
                  borderRadius: "6px",
                  border: "none",
                }}
              >
                Let’s start a conversation
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row  p-5" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <div style={{ marginTop: "1em" }}>
            In a competitive business environment, effective leadership is an
            essential requirement to reduce costly turnover, increase
            productivity, and that leads to a healthier bottom line. To do this,
            leaders must be able to provide inspiration, motivation, and clear
            direction to their team.
          </div>
          <div style={{ marginTop: "1em" }}>
            For any type or size of business, effective leadership will ensure
            your company will grow and prosper. In the absence of effective
            leadership, organizations may grow, but more slowly and may lose
            their direction and competitiveness. Understanding these
            foundational leadership skills and applying them successfully to
            your business will help you to become a better leader and will make
            your business thrive.
          </div>
          <div style={{ marginTop: "2em" }}>
            <h3>
              This is what the leadership development program is going to
              accomplish.
            </h3>

            <div style={{ marginTop: "1em" }}>
              <ul>
                <li>
                  {" "}
                  Assess, analyze, and improve your personal leadership
                  capabilities.{" "}
                </li>
                <li>
                  Understand the concepts that lead to improved employee
                  performance and adherence to your compliance standards.
                </li>
                <li>
                  Reduce leadership stress and allow additional free personal
                  time.  
                </li>
                <li>
                  Develop a personal action plan for each participating manager
                  and leader based on their individual requirements. 
                </li>
              </ul>
            </div>
            <div
              style={{ marginTop: "1em", fontSize: "22px", fontWeight: "bold" }}
            >
              We offer your company to participate in a comprehensive leadership
              the program with the guarantee that it will improve the
              effectiveness of your leaders and improve the productivity of your
              company here are the details of our ExecuGrowth Leadership
              Development Program
            </div>

            <div style={{ marginTop: "2em" }}>
              {/* <h3>Why Do You Need Credibility Selling System?</h3> */}
              <div style={{ marginTop: "1em" }}>
                <ol>
                  <li>
                    We will set up a 30 to 40-minute personal telephone
                    session before the Workshop to establish a personally
                    focused growth plan based on your results from the
                    behavioral assessments below. 
                  </li>
                  <li style={{ marginTop: "0.5em" }}>
                    We will establish a factual leadership foundation during
                    this 1-day Leadership Seminar and Workshop that will
                    allow you to understand yourself and help you manage and
                    lead others more effectively. 
                    <ol type="a" style={{ marginTop: "0.5em" }}>
                      <li>The 12 Lessons of Leadership (L.E.A.D.)</li>
                      <li style={{ marginTop: "0.5em" }}>
                        The 6 Behavioral Leadership foundations.
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginTop: "0.5em" }}>
                    We will provide a future opportunity to have
                    quarterly one-on-one executive coaching sessions to track
                    your progress and achieve your goals.  
                  </li>
                </ol>
              </div>
            </div>

            <div style={{ marginTop: "2em" }}>
              <h2>What is included in your investment?</h2>
              <div style={{ marginTop: "1em" }}>
                <div>
                  <b> PXT Select Leadership Behavioral Assessment </b>(with
                  multiple reports included at no additional charge, including
                  an Individual report analyzing your thinking style, behavioral
                  traits and interests, a Leadership report, and a Coaching
                  report). Generated around these leadership foundational
                  topics: 
                  <div style={{ marginTop: "1em" }}>
                    <ul>
                      <li>
                        <b> Creating a Vision –  </b>Imaging new ideas,
                        directions, and innovations; redefining what is
                        possible.
                      </li>
                      <li>
                        1<b> Developing Strategies –  </b>Transforming ideas
                        into actions that are innovative and achievable.
                      </li>
                      <li>
                        <b> Ensuring Results – </b>Implementing organizational
                        priorities and making sure they are carried out
                        accurately and efficiently.
                      </li>
                      <li>
                        <b> Inspiring People – </b>Creating clarity and unity
                        around ideas is the core of inspiration. Communicating
                        with contagious enthusiasm. Convincing others of the
                        merits of their cause or line of reasoning.
                      </li>
                      <li>
                        <b> Being Approachable – </b>Empowering team members to
                        come forward to get clarification, address concerns and
                        present ideas. Open to feedback and building morale by
                        making others feel heard. .
                      </li>
                      <li>
                        <b> Mentoring Others – </b>Mentoring others is an
                        investment in the company’s future. Help team members
                        develop the skills, connections, and confidence to do
                        their job more effectively and grow the next generation
                        of leaders in the process.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 " style={{ padding: "0 5em" }}>
          <div
            style={{
              backgroundColor: "white",
              color: "#203AAB",
              padding: "2em 5em ",
              borderRadius: "22px",
            }}
          >
            <h3 style={{ fontWeight: "bold" }}>
              Who is the target audience for this program:
            </h3>
            <div style={{ marginTop: "1em" }}>
              <ul type="1" style={{ fontWeight: "bold" }}>
                <li>
                  {" "}
                  <b>
                    Ownership, senior management, and managers in the
                    repossession industry.{" "}
                  </b>
                </li>
                <li style={{ marginTop: "0.5em" }}>
                  Individuals who wish to improve their management and
                  leadership skills; to get the people who work for them to
                  follow the policies and procedures that produce better
                  outcomes
                </li>
                <li style={{ marginTop: "0.5em" }}>
                  The kind of manager that every employee wants to have as a
                  boss; Someone who leads, who teaches, who appreciates, and who
                  values their contribution. 
                </li>
                <li style={{ marginTop: "0.5em" }}>
                  Companies who wish to improve the ability of their teams to
                  work together to produce more effective and efficient
                  organizations.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-5">
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <div>
            <h3>
              The 12 Lessons of Leadership and how to apply these lessons to
              everyday situations.
            </h3>

            <div style={{ marginTop: "1em" }}>
              <b> Why do people leave their jobs?</b> In survey after survey,
              employees tell us this.
            </div>

            <ul style={{ marginTop: "1em", type: "disc" }}>
              <li style={{ marginTop: "0.5em" }}>
                <b> LEADING – </b>How to become better by deciding where to
                go? Leaders provide clear and understandable direction and
                support; each and every time; to every person with whom they
                deal.
                <ul style={{ marginTop: "1em", type: "disc" }}>
                  <li>Values</li>
                  <li>Priorities</li>
                  <li>Measurable Objectives</li>
                  <li>Building our plans from the “Customer back”</li>
                  <li>Organizational Structure</li>
                  <li>Task Direction</li>
                  <li>Reviewing our Progress</li>
                </ul>
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <b> EDUCATING –</b> How to become better by growing and
                learning?  Leaders allow their people to grow and learn by
                developing a challenging and creative workplace. They
                communicate more rather than less.  Learning
                <ul>
                  <li>Job Challenge</li>
                  <li>Performance Planning</li>
                  <li>Change Management</li>
                  <li>Communications</li>
                  <li>Personal Development</li>
                  <li>Productivity through Knowledge</li>
                  <li>Knowledge through Training</li>
                </ul>
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <b> APPRECIATING - </b>Recognizing the contribution – How to
                become better by building our teams. Leaders  need to appreciate
                and recognize people for the job they are doing; They say thank
                you for a job well done.
                <ul>
                  <li>Recognition</li>
                  <li>Inclusion</li>
                  <li>Development Planning</li>
                  <li>Empowerment </li>
                  <li>Celebration</li>
                  <li>Rejuvenation</li>
                  <li>Teamwork</li>
                </ul>
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <b> DOLLARIZING –</b> The storehouse of values -- Creating your
                employee’s R.O.I.  – How to be better by focusing on the
                results.  Leaders need to appreciate and recognize people for
                the job they are doing; They say thank you for a job well done.
                <ul>
                  <li>Results and Achievement</li>
                  <li>Promotions</li>
                  <li>Increased Responsibility</li>
                  <li>Pride</li>
                  <li>Growth</li>
                  <li>Productivity</li>

                  <li>Satisfaction</li>
                  <li>Money</li>
                </ul>
              </li>
            </ul>
            <div>
              Leadership is getting people to do what they otherwise would not
              want to do but will readily do so anyway.
            </div>
            <div style={{ marginTop: "1em" }}>
              <h5>
                To participate in this Leadership seminar and workshop, each
                participant will need to:
              </h5>
              <ol>
                <li>Pre-register for the session.</li>
                <li>
                  Take a brief survey on their personal leadership and
                  managerial challenges.
                </li>
                <li>
                  Take a PXT Select behavioral assessment so we can analyze
                  their individual results before the workshop. 
                </li>
                <li>
                  Participate in a personal review session and leadership
                  planning session. (telephone meeting)
                </li>
                <li>Read the material provided and come ready to learn. </li>
              </ol>
            </div>
          </div>

          <div style={{ marginTop: "0em" }}></div>
        </div>
        <div className="col-6 ">
          <div className="text-center">
          <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{ width: "450px" }}
          />
          </div>
          <div className="mt-4 " style={{margin:'6em'}} >
            <div style={{ fontSize: "20px",borderRadius:'22px' ,color:'#203AAB',backgroundColor:'#F5F5F5',padding:'2em 4em' }}>
             <b> To participate in this Leadership seminar and workshop, each
              participant will need to:</b>
              <ol style={{marginTop:'1.2em'}}>
                <li>Pre-register for the session</li>
                <li>
                  Take a brief survey on their personal leadership and
                  managerial challenges.
                </li>
                <li>
                  Take a PXT Select behavioral assessment so we can analyze
                  their individual results before the workshop
                </li>
                <li>
                  Participate in a personal review session and leadership
                  planning session. (telephone meeting)
                </li>
                <li>Read the material provided and come ready to learn. </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImproveLeadershipSkills;
