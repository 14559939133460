import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import team from "../img/drug_test_118320558web-min 1.png";

function VerificationServices() {
  return (
    <div className="p-5">
      <div className="row">
        <div className="col-6 p-2" style={{ color: "#203AAB" }}>
          <h3>Education Verification</h3>
          <div style={{ marginTop: '1em' }}>
            Our specialists will verify an applicant’s educational background, confirming dates of attendance and highest degree completed. Certain Institutions require signed releases from the applicant in order to verify these searches.
          </div>
          <div style={{ marginTop: '1em' }}>
            *Average turnaround time: 1-3 business days
          </div>

          <h3 style={{ marginTop: '2em' }}>Professional License Verification</h3>
          <div style={{ marginTop: '1em' }}>
            We’ll verify licenses held, license status, expiration date, and other information as provided by the licensing agency.
          </div>
          <div style={{ marginTop: '1em' }}>
            *Average turnaround time: 1-3 business days
          </div>

          <h3 style={{ marginTop: '2em' }}>Personal/Professional Reference</h3>
          <div style={{ marginTop: '1em' }}>
            Our specialists are trained to verify all information submitted as well as ask pertinent questions to identify any hidden issues.
          </div>
          <div style={{ marginTop: '1em' }}>
            *Average turnaround time: 1-3 business days
          </div>
          <div style={{ marginTop: '1em' }}>
            **3rd party fees are applied and may be billed separately
          </div>

          <h3 style={{ marginTop: '2em' }}>Verification – DOT Employment Verification</h3>
          <div style={{ marginTop: '1em' }}>
            This service verifies the applicant’s previous or current Department of Transportation (DOT) related employment; start date, end date, title, salary, eligibility for rehire, and reason for leaving in addition to six standard DOT follow-up questions.
          </div>
          <div style={{ marginTop: '1em' }}>
            *Average turnaround time: 1-3 business days
          </div>

          <h3 style={{ marginTop: "2em" }}>Verification - DOT Drug and Alcohol Questionnaire</h3>
          <div style={{ marginTop: '1em' }}>
            Our specialists obtain answers to the standard Department of Transportation (DOT) drug and alcohol questionnaire.
          </div>
          <div style={{ marginTop: '1em' }}>
            *Average turnaround time: 1-3 business days
          </div>
        </div>

        <div className="col-6 ">
        <div style={{color:'#203AAB',padding:'1em 8em'}}>
            <h3>
            Landlord Verification
            </h3>
            <div style={{marginTop:'1em', marginBottom:'1em'}}>Our specialist are trained verify all information submitted as well as ask pertinent questions to identify any hidden issues.</div>
            <div style={{marginBottom:'2em'}}>*Average turnaround time: 1-3 business days </div>
            <h3>Employment Verification</h3>
            <div style={{marginTop:'1em',marginBottom:'1em'}}> Our specialists will contact the applicant’s prior employer and conduct a phone interview to verify the applicant’s previous or current employment: start date, end date, title, salary and eligibility for rehire. </div>
            <div style={{marginBottom:'2em'}}>*Average turnaround time: 1-3 business days </div>
            <h3>Professional License Verification</h3>
            <div style={{marginTop:'1em',marginBottom:'1em'}}> We’ll verify licenses held license status, expiration date and other information as provided by the licensing agency. </div>
            <div >*Average turnaround time: 1-3 business days</div>
       
            

        </div>
          
          <div className="text-center mt-2">
            <button style={{color:'#203AAB',backgroundColor:'#F7921E',fontWeight:'bold',padding:'1em 1.5em',border:'none',borderRadius:'8px'}} >
                Contact options</button>
          </div>
      
        </div>
        
      </div>
    </div>
  );
}

export default VerificationServices;
