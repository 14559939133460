import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from  "../img/Teamlogo.png";

function HiringSection() {
  return (
    <>
    <div className="p-5">
      <div className="row align-items-center">
        <div className="col-6 " style={{color:'#203AAB'}}>
          <h1>Struggling with hiring, and retaining top talent?</h1>
          <div style={{marginTop:'2em'}}>
            Recruiting, hiring and retaining great employees are the most
            difficult HR challenges facing employers today, so if this
            challenge is a stone in your shoe connect with us today and we
            will help you understand how to hire smart.
          </div>
        </div>
        <div className="col-6 text-center">
          <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{width:'450px'}}
          />
        </div>
      </div>
     
    </div>

<div className="row align-items-center p-5" style={{backgroundColor:'#F5F5F5'}}>
<div className="col-6 " style={{color:'#203AAB'}}>
  <div>For over 35 years, we've been dedicated to helping companies accurately identify candidates who are not only the right fit for the job but also align with the organization's culture and values. We recognize that finding the right people for the job can be challenging, especially when relying solely on traditional methods like reviewing résumés and conducting interviews. These methods often don't provide a complete picture of a candidate's potential, which can lead to costly hiring mistakes.</div>
  <div style={{marginTop:'2em'}}>
  Our extensive experience has shown that when companies implement our recommended processes—pre-qualifying candidates, conducting structured interviews, utilizing reliable assessment tools, and maintaining clear communication—they can significantly reduce turnover by anywhere from 15 to 50%. This reduction in turnover translates to substantial improvements in overall productivity and employee satisfaction.
 
  </div>
  <div style={{marginTop:'2em'}}>
  We don't just focus on filling positions; we aim to ensure that the individuals you bring on board are the best possible match for both the role and your organization as a whole. By using our dependable tools and proven strategies, you'll be better equipped to assess candidates for both organizational and job fit, making your hiring decisions more informed and effective. Let us help you streamline your hiring process and build a team that not only meets but exceeds your expectations." 
 
  </div>

</div>
<div className="col-6 text-center">
  {/* <img
    src={teamLogo}
    alt="Hiring Challenge"
    className="img-fluid"
    style={{width:'450px'}}
  /> */}
  <div style={{color:'#203AAB', backgroundColor:'white',border:'1px solid white', borderRadius:'22px',fontWeight:'bold',margin:'2em 6.5em'}} className=" align-items-center  p-4 ">

  <div style={{fontSize:'20px'}}>
  By following our recommended processes,<p>
    
     companies can cut turnover by </p>
  </div>
  <div style={{fontSize:'65px',fontWeight:'bold'}}>
  15 to 50%
  </div>
  <div style={{fontSize:'22px' ,marginTop:'1em'}}>
  boosting productivity and satisfaction.
  </div>

  </div>

  <div >
    <button style={{color:'#203AAB',border:'none',backgroundColor:'#F7921E',fontWeight:'bold',fontSize:'24px',padding:'5px 18px',borderRadius:'10px'}}>
    Let’s start a conversation
    </button>
  </div>

</div>
</div>
</>
  );
}

export default HiringSection;
