import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/saleswoman 1.png";
import team from  "../img/drug_test_118320558web-min 1.png";

function ImproveSalesPerformance() {
  return (
    <>
      <div className="p-5">
        <div className="row ">
          <div className="col-6 " style={{ color: "#203AAB" }}>
            <div>
            5 Panel Urinalysis, Paperless, Quick Check
             
            </div>
            <div style={{marginTop:'1.5em'}}>
            Amphetamines, Cocaine, Opiates, PCP and Marijuana. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: Immediate notification after urine sample collected if no flags, or 1-3 business days after applicant submits sample if test is positive. ** 3rd party collection site fees are applied and may be billed separately as needed if outside network data collection site is involved. 8 Panel Urinalysis Amphetamines, Cocaine, Opiates, PCP, Marijuana, Barbiturates, Benzodiazepines and Methadone. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed. 10 Panel Urinalysis Amphetamines, Cocaine, Opiates, PCP, Marijuana, Barbiturates, Benzodiazepines, Methadone, Propoxyphene, and Methaqualone. All positive results are automatically confirmed through Gas Chromatography/ Mass Spectrometry. A MRO (Medical Review Officer who is a medical doctor and an expert in the substance abuse field) will review any screenings with positive results. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>
            <div style={{marginTop:'1.5em'}}>
            5-Panel Urinalysis with Expanded Opiates Amphetamines, Cocaine, Opiates, PCP, Marijuana and Oxycodone. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{marginTop:'1.5em'}}>
            10-Panel Urinalysis with Expanded Opiates Amphetamines, Cocaine, Opiates, PCP, Marijuana, Barbiturates, Benzodiazepines, Methadone, Propoxyphene, Methaqualone and Oxycodone. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. If a 3rd party collection site is needed it may result in an additional fee. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed. 12-Panel Urinalysis Amphetamines, Barbiturates, Benzodiazepines, Cocaine, Marijuana, Methadone, Methamphetamine, MDMA, Opiates, Phencyclidine, Propoxyphene, and Oxycodone. 
            </div>

            <div style={{marginTop:'1.5em'}}>
            Includes collection, initial screen, confirmation GC/MS screen if needed and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{marginTop:'1.5em'}}>
            5-Panel Hair Amphetamines, Cocaine, Opiates, PCP and Marijuana. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{marginTop:'1.5em'}}>
            5-Panel Saliva Amphetamines, Cocaine, Opiates, PCP and Marijuana. Includes overnight shipping to lab, initial screen, confirmation GC/MS screen if needed and MRO This device is self collected and uses a $4.00 collection kit, sold separately. *Average turnaround time: 1-3 business days after applicant submits sample
            </div>

            <div style={{marginTop:'1.5em'}}>
            Healthcare Panel Urinalysis Amphetamines, Cocaine, Opiates, PCP, Marijuana, Barbiturates, Benzodiazepines, Methadone, Propoxyphene, Methaqualone, Fentanyl, Tramadol, Meperidine and Oxycodone. Some of the above drugs are tested at lower cut-off levels than a standard drug screen. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{marginTop:'1.5em'}}>
            Steroid Panel Urinalysis Panel including Anabolic steroids, masking agents, stimulants, narcotics, beta-blockers & diuretics. Includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{marginTop:'1.5em'}}>
            DOT 5-Panel Urinalysis Amphetamines, Cocaine, Opiates, PCP and Marijuana. Fee includes collection, initial screen, confirmation GC/MS screen if needed, and MRO. Review all according to DOT Regulations 49 CFR Part 40. *Average turnaround time: 1-3 business days after applicant submits sample ** 3rd party collection site fees are applied and may be billed separately as needed.
            </div>

            <div style={{ marginTop: "0em" }}></div>
          </div>
          <div className="col-6 text-center">
            <img
              src={team}
              alt="Hiring Challenge"
              className="img-fluid"
              style={{}}
            />
          </div>
        </div>
      </div>

  

   
    </>
  );
}

export default ImproveSalesPerformance;
