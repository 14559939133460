import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import teamLogo from "../img/Assessments_AdobeStock_639691757web-min 1.png";

function BuildYourHumanSystem() {
  return (
    <>
      <div
        className="row align-items-center p-5"
        style={{ backgroundColor: "" }}
      >
        <div className="col-6 " style={{ color: "#203AAB" }}>
          <h1>build your human system</h1>
          <div style={{ marginTop: "2em" }}>
            You have built your operating, financial and marketing systems. We
            help you build the Human Systems integrated all your other systems.
            Many organizations do not know the true cost of their HR strategies.
            With the cost of recruiting on the uprise due to a more competitive,
            aging applicant pool, the high cost of turnover and new employee
            training it is imperative that employers get a firm handle on what
            their HR Program costs. Discover what opportunities you have to
            drive a higher return on human capital investments.
          </div>
          <div style={{ marginTop: "2em" }}>
            Take your first step to cut costs to improve your bottom line.
            Analyze your HR practices by using our HR ROI Opportunity
            Calculator. The time you spend analyzing and learning about ways to
            improve your current situation will absolutely provide dividends for
            years to come.
          </div>
          <div style={{ marginTop: "2em" }}>
            Our HR Opportunity Calculator will identify where you should focus
            to improve your Human System. Invest time and we will provide you
            with the calculator with no cost or obligation.
          </div>
        </div>
        <div className="col-6 text-center">

         <div >
         <img
            src={teamLogo}
            alt="Hiring Challenge"
            className="img-fluid"
            style={{ width: "450px" }}
          />
         </div>
         <div style={{marginTop:'1.5em'}}>
            <button style={{color:"#203AAB",backgroundColor:'#F7921E',border:'none',padding:'8px 16px',fontWeight:'bold',borderRadius:'8px' }} >
            Let’s start a conversation
            </button>
         </div>

        </div>
      </div>
    </>
  );
}

export default BuildYourHumanSystem;
